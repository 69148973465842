import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Flex, Heading, Text } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import SEO from '../components/SEO';

const BackgroundBox = styled(Flex)`
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 320px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  min-height:80vh;
`;

const NotFoundPage = () => {
  const { t } = useTranslation('page404');
  const backgroundImage = t('background.image.src');

  return (
    <Fragment>
      <SEO title="404" />
      <BackgroundBox backgroundImage={backgroundImage}>
        <Heading as="h1" color="text.primary">
          {t('title')}
        </Heading>
        <Text fontSize="large" color="text.primary">
          {t('text')}
        </Text>
      </BackgroundBox>
    </Fragment>
  );
};

NotFoundPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    data: PropTypes.array
  })
};

NotFoundPage.defaultProps = {
  pageContext: {
    locale: 'en-gb',
    data: []
  }
};

export const query = graphql`
  query($locale: String) {
    ...Page404Data
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...MenuData
  }
`;

export default NotFoundPage;
